import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"

firebase.initializeApp({
  apiKey: "AIzaSyAxZdmwp7tQxGv6GGvc62J5VwiEeWpqOXU",
  authDomain: "tsc-web.firebaseapp.com",
  databaseURL: "https://tsc-web.firebaseio.com",
  projectId: "tsc-web",
  storageBucket: "tsc-web.appspot.com",
  messagingSenderId: "678881292895",
  appId: "1:678881292895:web:d5b6bc4b5af5cb95",
  measurementId: "G-8WGMMKLJ73"
});

export default firebase;
